import React, { useEffect, useState } from 'react'

import SEO from "components/seo"
import Layout from "components/layout"
import { GlobalCover } from "components/global/cover/cover"
import { Card } from "components/anti/card/card"


export default function EducationLanding({ path, location, pageContext }) {
  const lang = pageContext.langKey
  const data = lang === "en" ? pageContext.category : pageContext?.category?.translation || pageContext.category
  const schoolCategories = [...new Set(pageContext.scCategory?.map(el => el.slug ? el : ''))]
  const [educationList, setEducationList] = useState([])

  const cover = data.facilityCategoriesPage.cover
  const bgColor = data.backgroundColor.backgroundColor.color
  const headline = data.facilityCategoriesPage.cover.highlight

  const { seo } = data
  const SEODefaultImg = cover.image.sourceUrl

  useEffect(() => {
    const educationData = []
    schoolCategories.forEach(el => {
      const isExist = educationData.find(el2 => el.slug === el2.slug)
      if (!isExist) {
        const dataPush = {
          title: lang === "en" ? el?.name : el?.translation?.name || el?.name,
          slug: el?.slug,
        }
        educationData.push(dataPush)
      }
      setEducationList(educationData)
    });
  }, [])
  const linkArray = location.pathname.split('/')
  const breadPath = linkArray.map((el, i) =>
    i !== (pageContext.langKey === 'id' ? 3 : 2)
      ? el : ''
  ).join('/')

  return (
    <Layout path={path} theme={bgColor || "light"} lang={lang}>
      <SEO
        title={seo.title}
        desc={seo.metaDesc}
        fbImg={SEODefaultImg}
        fbTitle={seo.opengraphTitle}
        fbDesc={seo.opengraphDescription}
        url={location.href}
        twitterTitle={seo.twitterTitle}
        twitterDesc={seo.twitterDescription}
        twitterImg={SEODefaultImg}
      />
      <GlobalCover data={cover} theme={bgColor || "light"} breadcrumb={breadPath} />
      <section className={`pt-5`}>
        <div className={`container`}>
          <h2>{headline.title}</h2>
          <p>{headline.text}</p>
        </div>
      </section>
      <section className="pt-5 pb-main bg-light sc-facilities-education">
        <section className="container">
          {educationList?.map((item, i) => {
            let count = 0

            schoolCategories.map(el => {
              if (el.slug === item.slug) {
                count = count + 1
              }
            })
            return (
              <Card
                key={i}
                title={`${count}`}
                text={item?.title}
                textClassName="text-muted"
                className="education-list shadow mb-3"
                link={`${location?.pathname}/${item?.slug}`}
              />
            )
          })}
        </section>
      </section>
    </Layout>
  )
}
